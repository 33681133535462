.login-page {
  background-image: url(../../public/images/background-2.png);
  background-position: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100vh;

  height: 100vh;
  padding-top: 100px;
  .ant-pro-form-login-container {
    width: 440px;
    margin: 0 auto;
    padding-left: 0;
    padding-right: 0;
    padding-top: 30px;
    padding-bottom: 50px;
    background: #fff;
    height: auto;
    border-radius: 10px;
    // margin-top: 50px;
    .ant-pro-form-login-desc {
      margin-block-end: 20px;
    }
  }
  .btn-social {
    margin-bottom: 15px;
  }
  .btn-login-azure {
    width: 100%;
    display: block;
    width: 100%;
    display: block;
    // height: 40px;
    // line-height: 40px;
    text-align: center;
  }
  .btn-login-google {
    width: 100%;
    display: block;
    width: 100%;
    display: block;
    // height: 40px;
    // line-height: 40px;
    text-align: center;
  }
  span {
    // line-height: 30px;
    vertical-align: top;
  }
  .icon-azure {
    width: 20px;
    height: 20px;
  }
  .label-signin-with-email {
    width: 100%;
    display: block;
    text-align: center;
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 15px;
  }
}
