.form-edit-bot-instance {
  .form-item-label-has-subtitle label {
    position: relative;
    height: 40px;
    align-items: baseline;
    display: block;
    .subtitle {
      position: absolute;
      right: 13px;
      font-size: 12px;
      color: #9b9b9b;
      bottom: 5px;
    }
  }
}
