$marginSizes: -15 -10 -5 0 1 2 3 4 5 6 7 8 9 10 12 13 14 15 16 17 18 19 20 21 22 23 24 25 26 27 28 29 30 31 32 33 34 35 36 37 38 39 40 45 50 60 70 80 90 100;
@each $size in $marginSizes {
  .mr-top-#{$size} {
    margin-top: #{$size}px !important;
  }
  .mr-bottom-#{$size} {
    margin-bottom: #{$size}px !important;
  }
  .mr-left-#{$size} {
    margin-left: #{$size}px !important;
  }
  .mr-right-#{$size} {
    margin-right: #{$size}px !important;
  }
  .mr-top-bottom-#{$size} {
    margin-top: #{$size}px !important;
    margin-bottom: #{$size}px !important;
  }
  .mr-left-right-#{$size} {
    margin-left: #{$size}px !important;
    margin-right: #{$size}px !important;
  }
  .mr-#{$size} {
    margin: #{$size}px !important;
  }
}

$paddingSize: -15 -10 -5 0 1 2 3 4 5 6 7 8 9 10 12 13 14 15 16 17 18 19 20 21 22 23 24 25 26 27 28 29 30 31 32 33 34 35 36 37 38 39 40 45 50 60 70 80 90 100;
@each $size in $paddingSize {
  .pd-bottom-#{$size} {
    padding-bottom: #{$size}px !important;
  }
  .pd-left-#{$size} {
    padding-left: #{$size}px !important;
  }
  .pd-right-#{$size} {
    padding-right: #{$size}px !important;
  }
  .pd-top-#{$size} {
    padding-top: #{$size}px !important;
  }
  .pd-top-bottom-#{$size} {
    padding-top: #{$size}px !important;
    padding-bottom: #{$size}px !important;
  }
  .pd-left-right-#{$size} {
    padding-left: #{$size}px !important;
    padding-right: #{$size}px !important;
  }
  .pd-#{$size} {
    padding: #{$size}px !important;
  }
  .line-height-#{$size} {
    line-height: #{$size}px !important;
  }
}
