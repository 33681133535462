.chat-history {
  .chat-histories {
    // padding-bottom: 50px;
  }
  .filter-block {
    margin-bottom: 10px;
    .ant-picker.ant-picker-range {
      width: 100%;
      .ant-picker-input input {
        // font-size: 12px;
      }
    }
    .select-tags,
    .select-bot {
      margin-top: 5px;
      margin-bottom: 5px;
    }
  }
  .search-block {
    margin-bottom: 15px;
  }
  .paging-block {
    margin-top: 15px;
    .ant-pagination-item {
      min-width: 24px;
    }
    .ant-select-selector,
    .ant-pagination-options {
      display: none;
    }
  }
  .chat-list {
    border-radius: 10px;
    padding: 10px;
    height: calc(100vh - 30px);
    background: #ffffff;
    overflow: scroll;
    .conversations-item {
      padding: 10px 5px;
      border-bottom: 1px solid #f1f1f1;
      position: relative;
      cursor: pointer;
      border-radius: 5px;
      &.active {
        // background: #ffe8d3;
        background: #fffcfa;
        border: 1px solid #ffdec1;
        border-radius: 5px;
      }
      .avatar {
        cursor: pointer;
      }
      .profile {
        vertical-align: middle;
        margin-left: 5px;
      }
      .username {
        cursor: pointer;
        font-size: 12px;
        color: #151515;
      }
      .first-msg {
        font-size: 10px;
        color: #7d7d7d;
      }
      .time-created {
        position: absolute;
        top: 5px;
        right: 5px;
        font-size: 10px;
        color: #747474;
      }
      .rating {
        position: absolute;
        bottom: 5px;
        right: 5px;
        .ant-rate-star {
          font-size: 12px;
          margin: 2px;
        }
      }
      .tags {
        display: block;
        margin: 5px;
        .ant-tag {
          font-size: 10px;
          line-height: 14px;
          padding: 0 5px;
        }
      }
    }
  }
  .chat-block {
    // background: #a87676;
    border-radius: 10px;
    padding: 10px;
    height: calc(100vh - 30px);
    background: #ffffff;
    overflow: scroll;
    border-left: 1px solid #f1f1f1;
    .chat-histories {
      .chat-histories-header {
        padding-bottom: 10px;
        border-bottom: 1px solid #f1f1f1;
        .bot-name {
          font-size: 14px;
          color: #000000;
        }
        .bot-description {
          font-size: 12px;
          color: #7d7d7d;
        }
      }
      .chat-histories-messages {
        .history-message {
          display: block;
          width: 100%;
          margin-top: 30px;
          .history-message-content {
            display: inline-block;
            position: relative;
            .message {
              padding: 15px;
              margin-left: 10px;
              border-radius: 10px;
              text-align: left;
              display: inline-block;
              overflow-wrap: break-word;
              white-space: pre-line;
              word-break: break-word;
            }
            .avatar {
              border: 2px solid white !important;
            }
            .timeCreated {
              position: absolute;
              color: #7d7d7d;
              font-size: 10px;
              position: absolute;
              bottom: -15px;
            }
          }
          &.question-message {
            text-align: right;
            .history-message-content {
              min-width: 30%;
              display: inline-flex;
              .message {
                display: inline-block;
                background-color: #e9613c !important;
                color: white;
                text-align: right;
                width: 100%;
              }
            }
            .avatar {
              position: absolute;
              bottom: -20px;
              right: 0px;
            }
            .timeCreated {
              left: 10px;
              bottom: -12px;
            }
            .retrain-message {
              opacity: 0;
            }
          }
          &.chat-bot-message {
            text-align: left;
            .history-message-content {
              min-width: 50%;
              max-width: 80%;
            }
            .history-message-content .message {
              display: inline-block;
              background-color: #f5f6fa;
              color: #252525 !important;
              width: 100%;
            }
            .avatar {
              position: absolute;
              bottom: -20px;
              left: -5px;
            }
            .timeCreated {
              right: 0px;
            }
            .retrain-message {
              position: absolute;
              left: 32px;
              bottom: -16px;
              cursor: pointer;
            }
          }
        }
      }
    }
  }
}
