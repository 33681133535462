@import './styles/antd_color_button'; // or 'antd-button-color/dist/css/style.less'
@import './styles/margin_padding.scss'; // or 'antd-button-color/dist/css/style.less'
@import './styles/botInstance.scss';
@import './styles/trainDataPage.scss';
@import './styles/loginPage.scss';
@import './styles/chat_history.scss';
@import './styles/apikey_page.scss';
@import './styles/home_page.scss';
.pointer {
  cursor: pointer !important;
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.ant-layout {
  background: #fff;
}
.sidebar-brandname {
  margin-left: 5px;
  color: gray;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 160px;
  font-size: 14px;
  font-weight: bold;
  color: #494949;
}
.sidebar-brandlogo {
}
.anticon {
  // font-size: 20px;
  &.ant-dropdown-menu-item-icon {
    font-size: 14px !important;
  }
}
.app-menu-left-sidebar {
  .ant-menu-item {
    height: 30px;
    line-height: 30px;
  }
  .ant-menu-item-group-title {
    padding: 5px 0 5px 10px;
  }
}
