.homePage {
  .general-block {
    margin-top: 15px;
    background: #fff;
    padding: 20px;
    position: relative;
    border-radius: 10px;
    .icon {
      font-size: 40px;
      border-radius: 5px;
      position: absolute;
      top: 20px;
      right: 20px;
    }
    .title {
      font-size: 40px;
      line-height: 40px;
    }
    .description {
      font-size: 12px;
      line-height: 20px;
      color: #6a6a6a;
    }
    .up-down {
      margin-top: 10px;
      .since-last-month {
        margin-left: 5px;
      }
    }
  }
  .chart-item {
    padding: 10px;
    background-color: #fff;
    border-radius: 5px;
    margin-top: 30px;
  }
}
